<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Kampanjer</h1>
				<h3 v-if="campaigns">Totalt {{campaigns.length}} {{campaigns.length > 1 ? 'kampanjer' : 'kampanje'}}</h3>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<div 
				v-if="loading == 'fetching'"
				class="h-full flex items-center justify-center">
				
				<div class="spinner"></div>
			</div>
			<div 
				v-else-if="campaigns"
				class="
					grid
					gap-6 
					md:gap-12
					grid-cols-1
					lg:grid-cols-2
					xl:grid-cols-3
				">
				
				<a 
					v-for="campaign in campaigns"
					:key="'campaign_'+campaign.campaignId"
					@click="campaignEdit = campaign"
					class="flex">
					
					<CampaignCard 
						:campaign="campaign"
					/>
				</a>
			</div>
		</div>
		
		<div class="absolute bottom-0 right-0 m-6">
			<button 
				@click="showCampaignNew = true"
				class="button submit round">
				
				<i class="fas fa-plus" />
			</button>
		</div>
		
		<Modal 
			v-if="showCampaignNew"
			@close="showCampaignNew = false"
			insideClasses="bg-white">
			
			<header slot="header">
				Ny kampanje
			</header>
			
			<form 
				@submit.prevent="onCreateCampaign()"
				class="py-2 px-6">
				
				<InputSingleline 
					v-model="campaignNew.name"
					required="true"
					label="Kampanjenavn"
					name="name"
					showRequiredWarning="true"
					ref="autofocus"
				/>
				
				<label for="departmentId">Avdeling *</label>
				<select 
					v-model="campaignNew.departmentId"
					id="departmentId">
					
					<option 
						v-for="(department,index) in departments"
						:key="'departmentId_'+department.departmentId+'_'+index"
						:value="department.departmentId">
						
						{{department.name}}
					</option>
				</select>
				
				<label>Kampanjetype</label>
				<label class="mt-2">
					<input 
						type="radio" 
						v-model="campaignNew.type"
						value="products"
						class="mr-4 mt-1"
					/>
					Produkter
				</label>
				<label class="mt-2">
					<input 
						type="radio" 
						v-model="campaignNew.type"
						value="tasks"
						class="mr-4 mt-1"
					/>
					Oppgaver
				</label>
			</form>
			
			<footer slot="footer">
				<div class="space-x-4 text-right">
					<a 
						class="button" 
						@click="showCampaignNew = false">
						
						Avbryt
					</a>
					
					<button 
						type="submit"
						@click.prevent="onCreateCampaign()" 
						:disabled="loading == 'creating'"
						class="button submit"
						:class="{'loading' : loading == 'creating'}">
						
						Opprett
					</button>
				</div>
			</footer>
		</Modal>
		
		<div 
			v-if="campaignEdit"
			class="absolute inset-0 flex">
			
			<div 
				@click="campaignEdit = null"
				class="flex-grow bg-black opacity-25 ">
				
			</div>
			
			<transition name="fade">
				<CampaignEdit 
					:campaign="campaignEdit" 
					@close="campaignEdit = null"
					class="
						absolute bg-white right-0 overflow-hidden w-full
						md:rounded-l-lg 
						md:w-150
						md:shadow-2xl
					">
					
					<header slot="header">
						<div class="flex-grow flex px-4 pt-4">
							<div 
								class="flex-none w-12 h-12 flex items-center justify-center"
								@click="campaignEdit = null">
								
								<i class="fas fa-times text-black text-xl" />
							</div>
							<div class="flex-grow flex items-center justify-center">
								<h1>{{campaignEdit.name}}</h1>
							</div>
							<div class="flex-none w-12 h-12 flex items-center justify-center">
								
							</div>
						</div>
					</header>
				</CampaignEdit>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
			'CampaignEdit': () => import('@/components/campaigns/CampaignEdit.vue'),
			'CampaignCard': () => import('@/components/campaigns/CampaignCard.vue'),
		},
		
		computed:{
			campaigns(){
				const campaigns = this.$store.getters.getCampaigns;
				
				if ( !campaigns ) {
					return [];
				}
				
				if ( !this.departments ) {
					return [];
				}
				
				return campaigns.filter( campaign => {
					return this.departments.find( department => department.departmentId == campaign.departmentId );
				});
			},
			
			departments(){
				return this.$store.getters.getDepartments;
			},
		},
		
		data(){
			return {
				campaignEdit: null,
				campaignNew: {
					name: null,
					departmentId: null,
					description: null,
					type: 'products',
					
					products: [],
					tasks: [],
					startAt: this.$moment().startOf('day').valueOf(),
					endAt: this.$moment().startOf('day').add(1, 'w').valueOf(),
					published: false,
				},
				showCampaignNew: false,
				loading: null,
			}
		},
		
		watch: {
			campaignNew: {
				immediate: false,
				deep: true,	
				handler() {
					// console.log('campaignNew departmentId', this.campaignNew.departmentId);
					
					if (
						this.departments &&
						this.campaignNew && 
						this.campaignNew.departmentId ) {
						
						const department = this.departments.find( d => d.departmentId == this.campaignNew.departmentId);
						// console.log( department );
						
						if ( !department ){
							return null;
						}
						
						// check if the chosen department has both products AND tasks
						if ( 
							department.tasks &&
							department.tasks.length &&
							(!department.products || !department.products.length) ){
							
							this.campaignNew.type = 'tasks';
						}
					}
				}
			},
		},
		
		methods: {
			async onCreateCampaign(){
				try {
					if ( !this.campaignNew.name ) {
						throw Error('En kampanje må ha et navn');
					}
					if ( !this.campaignNew.departmentId ) {
						throw Error('En kampanje må være tilknyttet en avdeling');
					}
					
					this.loading = 'creating';
					
					const campaign = await this.$store.dispatch('createCampaign', this.campaignNew);
					console.log('campaign response', campaign);
					
					this.campaignNew = {
						...this.campaignNew,
						name: null,
						departmentId: null,
						description: null,
						// type: 'products',
					};
					
					this.showCampaignNew = false;
					this.campaignEdit = campaign;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Kunne ikke opprette kampanje'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			this.loading = 'fetching';
			
			await Promise.allSettled([
				this.$store.dispatch('listCampaigns'),
				this.$store.dispatch('listDepartments'),
			]);
			
			this.loading = null;
			
			if (this.$route.params.campaignId) {
				this.campaignEdit = this.$store.getters.getCampaigns.find(campaign => campaign.campaignId == this.$route.params.campaignId);
			}
		},
	}
</script>

<style lang="scss" >

</style>
